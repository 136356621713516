import { createStore } from "vuex";

export default createStore({
  state: {
    menuId:'',
  },
  mutations: {
    getMenuId(state,data){
      state.menuId = data
    }
  },
  actions: {},
  modules: {},
});
