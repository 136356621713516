<template>
  <div>
    <router-view></router-view>
  </div>

</template>
  <script>
import { watch, onMounted, toRefs, reactive } from 'vue'
import { useRouter } from "vue-router";
export default {
  setup() {
    const route = useRouter();
    watch(() => route.currentRoute.value.path, (count, prevCount) => {
      let url = count.split('/')
      localStorage.setItem('lang', url[1])
    }, { immediate: true })
    const state = reactive({
      value: ''
    })


    onMounted(() => {

    })
    return {
       ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
  @import './font/font.css';
  #app {
    font-family: 'PingFang-RE';
    font-weight: 400;
  }
</style>
