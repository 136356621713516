//语言
import zh from './zh'
import en from './en'
import ja from './ja'

import { createI18n } from 'vue-i18n'		//引入vue-i18n组件
// import { 引入的组件 export 出来的 变量} from 'vue-i18n'
  //注册i8n实例并引入语言文件

  //定义语言包
  const messages = {
    zh: {
        ...zh
    },
    en: {
        ...en
    },
    ja:{
      ...ja
    }
}

 const i18n = createI18n({
    locale:localStorage.getItem('lang') || 'zh',		//默认显示的语言 
    // fallbackLocale:'zh',
      messages
  })
  
  export default i18n; //将i18n暴露出去，在main.js中引入挂载