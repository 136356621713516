import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as ELIconModules from "@element-plus/icons";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import i18n from "./lang/index";
import zh from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";
import "./utils/rem.js";
import "./assets/icon/style.css";
import './assets/css/app.css'
import './assets/icon/homeIcon/iconfont.css'
// 引入vant
import Vant from "vant";
import "vant/lib/index.css";
let locale = zh;
let str = localStorage.getItem("lang");
if (str === "zh") {
  locale = zh;
} else if (str === "en") {
  locale = en;
}
const app = createApp(App);
for (const iconName in ELIconModules) {
  app.component(iconName, ELIconModules[iconName]);
}
app.use(i18n);
app.use(store);
app.use(Vant);
app.use(ElementPlus, { locale });
app.use(router);
app.mount("#app");
