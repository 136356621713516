// 小驼峰命名
const Nav = {
  product:'Product Center',
  about_shinetech:'About Us',
  news:'News',
  collaborate:'Collaborate',
  join_us:'Join Us',
  contact_us:'Contact Us'
}

  // 按功能模块导出
  export default {
    Nav
  }