const Nav = {
  list:'数组',
  product:"产品中心",
  about_shinetech:'关于盛泰',
  news:'新闻资讯',
  collaborate:'合作与发展',
  join_us:'加入我们',
  contact_us:'联系我们'
}
  // 按功能模块导出
  export default {
    Nav
  }