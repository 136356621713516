import { createRouter, createWebHistory } from "vue-router";

let lang = localStorage.getItem('lang')
const routes = [
  {
    path:'/',
    redirect:'/zh'
  },
  // 中文
  {
    path:'/zh',
    component:()=>import('@/views/zh/home/home.vue')
  },
  {
    path:'/zh/product',
    name:'Product',
    component:()=>import('@/views/zh/product/product.vue')
  },

  {
    path:'/zh/about',
    name:'About',
    component:()=>import('@/views/zh/about/about.vue')
  },
  {
    path:'/zh/aboutId',
    name:'AboutId',
    component:()=>import('@/views/zh/about/aboutId.vue')
  },
  {
    path:'/zh/aboutImg',
    name:'AboutImg',
    component:()=>import('@/views/zh/about/aboutImg.vue')
  },
  {
    path:'/zh/news',
    name:'Journalism',
    component:()=>import('@/views/zh/news/news.vue')
  },
  {
    path:'/zh/newsId',
    name:'zhNewsId',
    component:()=>import('@/views/zh/news/newsId.vue')
  },
  {
    path:'/zh/cooperation',
    name:'Investor',
    component:()=>import('@/views/zh/cooperation/cooperation.vue')
  },

  {
    path:'/zh/contactus',
    name:'contactus',
    component:()=>import('@/views/zh/contactus/contactus.vue')
  },
  {
    path:'/zh/join',
    name:'Join',
    component:()=>import('@/views/zh/join/join.vue')
  },
  {
    path:'/zh/joinId',
    name:'JoinId',
    component:()=>import('@/views/zh/join/joinId.vue')
  },
  // 品质
  {
    path:'/zh/quality',
    name:'Quality',
    component:()=>import('@/views/zh/about/quality.vue')
  },
  // 研发
  {
    path:'/zh/research',
    name:'Research',
    component:()=>import('@/views/zh/about/research.vue')
  },
  // 理念
  {
    path:'/zh/idea',
    name:'Idea',
    component:()=>import('@/views/zh/join/idea.vue')
  },
  // 校园招聘
  {
    path:'/zh/school',
    name:'School',
    component:()=>import('@/views/zh/join/school.vue')
  },
  // 制造环境
  {
    path:'/zh/manufacture',
    name:'Manufacture',
    component:()=>import('@/views/zh/about/manufacture.vue')
  },
  // 商业合作伙伴
  {
    path:'/zh/business_partner',
    name:'Business_partner',
    component:()=>import('@/views/zh/cooperation/business_partner.vue')
  },
  // 商业道德
  {
    path:'/zh/commercial_ethics',
    name:'Commercial_ethics',
    component:()=>import('@/views/zh/cooperation/commercial_ethics.vue')
  },
  // 社会与发展
  {
    path:'/zh/social_responsibility',
    name:'Social_responsibility',
    component:()=>import('@/views/zh/cooperation/social_responsibility.vue')
  },
  // 持续发展
  {
    path:'/zh/develop',
    name:'develop',
    component:()=>import('@/views/zh/cooperation/develop.vue')
  },
  // 持续发展

  
  // 英文
  {
    path:'/en',
    component:()=>import('@/views/en/home/home.vue')
  },
  {
    path:'/en/product',
    name:'ProductEn',
    component:()=>import('@/views/en/product/product.vue')
  },

  {
    path:'/en/about',
    name:'AboutEn',
    component:()=>import('@/views/en/about/about.vue')
  },
  {
    path:'/en/aboutId',
    name:'AboutIdEn',
    component:()=>import('@/views/en/about/aboutId.vue')
  },
  {
    path:'/en/aboutImg',
    name:'AboutImgEn',
    component:()=>import('@/views/en/about/aboutImg.vue')
  },
  {
    path:'/en/news',
    name:'JournalismEn',
    component:()=>import('@/views/en/news/news.vue')
  },
  {
    path:'/en/newsId',
    name:'enNewsId',
    component:()=>import('@/views/en/news/newsId.vue')
  },
    // 品质
    {
      path:'/en/quality',
      name:'QualityEn',
      component:()=>import('@/views/en/about/quality.vue')
    },
    // 研发
    {
      path:'/en/research',
      name:'ResearchEn',
      component:()=>import('@/views/en/about/research.vue')
    },
 
  {
    path:'/en/contactus',
    name:'contactusEn',
    component:()=>import('@/views/en/contactus/contactus.vue')
  },
  {
    path:'/en/join',
    name:'JoinEn',
    component:()=>import('@/views/en/join/join.vue')
  },
  {
    path:'/en/joinId',
    name:'JoinIdEn',
    component:()=>import('@/views/en/join/joinId.vue')
  },

  // 理念
  {
    path:'/en/idea',
    name:'EnIdea',
    component:()=>import('@/views/en/join/idea.vue')
  },
  // 制造环境
  {
    path:'/en/manufacture',
    name:'EnManufacture',
    component:()=>import('@/views/en/about/manufacture.vue')
  },
  {
    path:'/en/cooperation',
    name:'EnCooperation',
    component:()=>import('@/views/en/cooperation/cooperation.vue')
  },
  // 商业合作伙伴
  {
    path:'/en/business_partner',
    name:'EnBusiness_partner',
    component:()=>import('@/views/en/cooperation/business_partner.vue')
  },
  // 商业道德
  {
    path:'/en/commercial_ethics',
    name:'EnCommercial_ethics',
    component:()=>import('@/views/en/cooperation/commercial_ethics.vue')
  },
  // 社会与发展
  {
    path:'/en/social_responsibility',
    name:'EnSocial_responsibility',
    component:()=>import('@/views/en/cooperation/social_responsibility.vue')
  },
  // 持续发展
  {
    path:'/en/develop',
    name:'Endevelop',
    component:()=>import('@/views/en/cooperation/develop.vue')
  },

  // 日文
  {
    path:'/ja',
    component:()=>import('@/views/ja/home/home.vue')
  },
  {
    path:'/ja/product',
    name:'JaProduct',
    component:()=>import('@/views/ja/product/product.vue')
  },

  {
    path:'/ja/about',
    name:'JaAbout',
    component:()=>import('@/views/ja/about/about.vue')
  },
  {
    path:'/ja/aboutId',
    name:'JaAboutId',
    component:()=>import('@/views/ja/about/aboutId.vue')
  },
  {
    path:'/ja/aboutImg',
    name:'JaAboutImg',
    component:()=>import('@/views/ja/about/aboutImg.vue')
  },
  {
    path:'/ja/news',
    name:'JaJournalism',
    component:()=>import('@/views/ja/news/news.vue')
  },
  {
    path:'/ja/newsId',
    name:'jaNewsId',
    component:()=>import('@/views/ja/news/newsId.vue')
  },

  {
    path:'/ja/contactus',
    name:'JaContactus',
    component:()=>import('@/views/ja/contactus/contactus.vue')
  },
  {
    path:'/ja/join',
    name:'JaJoin',
    component:()=>import('@/views/ja/join/join.vue')
  },
  {
    path:'/ja/joinId',
    name:'JaJoinId',
    component:()=>import('@/views/ja/join/joinId.vue')
  },
 // 品质
 {
  path:'/ja/quality',
  name:'JaQuality',
  component:()=>import('@/views/ja/about/quality.vue')
},
// 研发
{
  path:'/ja/research',
  name:'JaResearch',
  component:()=>import('@/views/ja/about/research.vue')
},
// 理念
{
  path:'/ja/idea',
  name:'JaIdea',
  component:()=>import('@/views/ja/join/idea.vue')
},
// 制造环境
{
  path:'/ja/manufacture',
  name:'JaManufacture',
  component:()=>import('@/views/ja/about/manufacture.vue')
},
{
  path:'/ja/cooperation',
  name:'JaCooperation',
  component:()=>import('@/views/ja/cooperation/cooperation.vue')
},
// 商业合作伙伴
{
  path:'/ja/business_partner',
  name:'JaBusiness_partner',
  component:()=>import('@/views/ja/cooperation/business_partner.vue')
},
// 商业道德
{
  path:'/ja/commercial_ethics',
  name:'JaCommercial_ethics',
  component:()=>import('@/views/ja/cooperation/commercial_ethics.vue')
},
// 社会与发展
{
  path:'/ja/social_responsibility',
  name:'JaSocial_responsibility',
  component:()=>import('@/views/ja/cooperation/social_responsibility.vue')
},
// 持续发展
{
  path:'/ja/develop',
  name:'Jadevelop',
  component:()=>import('@/views/ja/cooperation/develop.vue')
},

  {
    path:'/:pathMatch(.*)*',
    name:'404',
    component:()=>import(`@/views/${lang}/err/404.vue`)
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
